import {Card} from "./Card";

var template = require('./congress_card.twig');
import { CustomModal } from "../../components/customModal";

export class CongressCard extends Card
{
    constructor() {
        super();
        let data= { is_multi_spec_proj_congress: app.appData.configs?.ENABLE_MULTI_SPEC_PROJ_CONGRESS_CARD ?? 0,
                    spec_proj_types: JSON.parse(app.appData.configs?.CONGRESS_SPEC_PROJ_TYPES ?? "{}")
                    };
        this.dom = $(twig({data: template}).render(data));

        this.dom.find('#pseudo_spec_proj_id').on('click', () => {
            let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
            let showCongressName = projProgTypeConf.sb.meeting_request.show_congress_name ?? false;

            if(showCongressName && this.dom.find('#pseudo_spec_proj_id').is(':checked')) {
                this.dom.find('#congress_name_container').show();
            } else {
                this.dom.find('#congress_name_container').hide();
            }
        })

        this.dom.find('input[name=pseudo_spec_proj_id]').on('change', () => {
            let projProgTypeConf = app.appData.projects[$('#project_dd').val()].program_types[$('#program_type_dd').val()].configuration;
            let showCongressName = projProgTypeConf.sb.meeting_request.show_congress_name ?? false;
            let CongressName = this.dom.find('input[name=pseudo_spec_proj_id]:checked').next('label').text();
            this.dom.find('#congress_name').val('');

            if (showCongressName && this.dom.find('input[name=pseudo_spec_proj_id]').is(':checked')) {
                if (CongressName != 'N/A'){
                    CongressName+= ' Name';
                    this.dom.find('#congress_name_container').show().find('label').text(CongressName);
                } else {
                    this.dom.find('#congress_name_container').hide().find('label').text("");
                }
            } else {
                this.dom.find('#congress_name_container').hide();
            }
        })

        this.dom.find('.congress_info').on('click', (event) => {
            let id  = $(event.currentTarget).attr('value');
            let congressData = JSON.parse(app.appData.configs.CONGRESS_SPEC_PROJ_TYPES)[id];
            new CustomModal({
                title: congressData.name,
                content: congressData.info,
            }).open();

        })
    }
}
