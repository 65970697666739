import { Page } from './page.js'
import { AttendeeList } from './manageattendance/AttendeeList'
import { AttendanceCounts } from './manageattendance/AttendanceCounts'
import { TargetSearch } from "./manageattendance/TargetSearch";
import { EmployeeSearch } from "./manageattendance/EmployeeSearch";
import { AddForm } from "./manageattendance/AddForm";
import { AddHipaa } from "./manageattendance/AddHipaa";
import { AddNonReportable } from "./manageattendance/AddNonReportable";
import { AttendeeQuestions } from "./manageattendance/AttendeeQuestions";
import { SendCommunication } from "./manageattendance/SendCommunication";
import { CommunicationHistoryModal } from "./manageattendance/CommunicationHistoryModal";
import { AttendeeDetailModal } from "./manageattendance/AttendeeDetailModal";
import { EmailMetricsModal } from "./manageattendance/EmailMetricsModal";
import { ManageGroup } from "./manageattendance/ManageGroup";
import { EditGroup } from "./manageattendance/EditGroup";
import { ApTargetSearch } from "./manageattendance/ApTargetSearch";
import { ValidateRsvp } from "./manageattendance/ValidateRsvp";
import { RegistrationLimitModal } from "./manageattendance/RegistrationLimitModal";
import { SpecialtyLegend } from './manageattendance/SpecialtyLegend'
import { ValidateRemote } from "./manageattendance/ValidateRemote";
import { CustomModal } from "../components/customModal";

let template = require('./manageattendance/manageattendance.twig');

export class ManageAttendance extends Page{

    getCanManage(async=true) {

       return $.ajax({
            url     : 'api/attendance/canmanage',
            method  : 'get',
            async  : async,
            data    : {
                meeting_id: this.meetingId,
            }
        });
   }

    getMeetingPhase(async=true) {

        return $.ajax({
            url     : 'api/attendance/meetingphase',
            method  : 'get',
            async  : async,
            data    : {
                meeting_id: this.meetingId,
            }
        });
    }

    loadCounts() {
        this.countAjax = $.ajax({
            url     : 'api/attendance/counts',
            method  : 'get',
            data    : {
                meeting_id: this.meetingId,
            },
            beforeSend:() => {
                if (this.countAjax != null)
                {
                    this.countAjax.abort()
                }
            }
        }).done((data) => {
            let config = {
                meetingPhase: this.meetingPhase,
                eventTypeConfig: this.eventTypeConfig
            }

            let countView = new AttendanceCounts(data, config);
            $('#counts').html(countView.dom);
        });
    }

    addTargetAttendee(data) {
        data.attendedOverride = this.meetingPhase == 'postmeeting' ? 1 : 2;
        data.hcp_compliance_check = this.meetingData.hcp_compliance_check;
        data.product_ids = this.meetingData.product_ids;
        return $.post(
            "/api/attendance/addtargetattendee",
            {
                'data': data,
                'meeting_id': this.meetingId
            },
            (data) => {
                if(data.success)
                {
                    $('.attendee-val-message').hide();
                    this.dom.trigger('participantAdded', [event, data.data]);
                    if(typeof app.appData.layout.meeting_request.approval_email_after_min_att !== 'undefined'
                        && app.appData.layout.meeting_request.approval_email_after_min_att) {
                        this.sendApprovalEmail();
                    }
                }
                else if(data.message)
                {
                    //Wordy enough warning that five seconds isn't enough.
                    $('.attendee-val-message > span').html(data.message + '<br>Click this message to hide it.');
                    $('.attendee-val-message').show();
                }
            }
        );
    }

    addEmployeeAttendee(data) {
        data.attendedOverride = this.meetingPhase == 'postmeeting' ? 1 : 2;
        return $.post(
            "/api/attendance/addemployeeattendee",
            {
                'data': data,
                'meeting_id': this.meetingId
            },
            (data) => {
                if(data.success)
                {
                    this.dom.trigger('participantAdded', [event, data.data]);
                }
            }
        );
    }

    removeAttendee(attendee_id)
    {
        return $.post(
            "/api/attendance/removeattendee",
            {
                'attendee_id': attendee_id,
                'meeting_id': this.meetingId
            }
        );
    }

    getMeetingData(async=true)
    {
        return $.ajax({
            url     : 'api/attendance/meetingdata',
            method  : 'get',
            async   : async,
            data    : {
                meeting_id: this.meetingId,
            }
        });
    }

    getCommunications() {
        return $.ajax({
            url     : `api/attendance/${this.meetingId}/communications`,
            method  : 'get',
        }).done((response) => {
            this.communications = response.data
        });
    }

    flashError(msg)
    {
        window.scrollTo(0, 0);
        $('.attendee-list-message > span').html(msg)
        $('.attendee-list-message').removeClass('alert-success').addClass('alert-danger').show().fadeOut(5000)
    }

    flashSuccess(msg)
    {
        $('.attendee-list-message > span').html(msg)
        $('.attendee-list-message').addClass('alert-success').removeClass('alert-danger').show().fadeOut(5000)
    }

    updateAttendee(data) {

        return $.ajax({
            url     : 'api/attendees/' + data['attendee_id'],
            method  : 'post',
            data    :  data
        })
    }

    registrationLimit(data){
        return $.get(
            "/api/attendance/registrationlimit",
            {
                'customer_id': data.customerId,
                'npi_num': data.npi_num,
                'address_id': data.addressId,
                'project_id': this.meetingData.project_id,
                'meeting_date': this.meetingData.meeting_date,
                'meeting_id': this.meetingId
            }
        );
    }

    sendApprovalEmail() {
        return $.ajax({
            url     : 'api/meeting/sendapprovalemail',
            method  : 'post',
            data    : {
                meeting_id: this.meetingId,
            }
        })
    }

    validateMaxParticipation(data) {
        return $.ajax({
            url     : 'api/attendance/validateremote',
            method  : 'get',
            data    : {
                meeting_id: data.meeting_id,
                customer_id: data.customer_id
            }
        })
    }

    updateArticleReprint(meetingid, attendeeid, received_article_reprint, count) {
        return $.ajax({
            url: 'api/attendance/articlereprint',
            method: 'post',
            data: {
                meeting_id: meetingid,
                attendee_id: attendeeid,
                received_article_reprint: received_article_reprint,
                article_reprint_count: count
            }
        })
    }

    loadRSVPTimer(rsvpDeadlineDays){
        let timezone = this.meetingData.timezone;
        let meeting_date =  moment(this.meetingData.meeting_date).format('L');
        let meeting_time = moment(this.meetingData.meeting_time, ["h:mm A"]).format("HH:mm");

        $.ajax({
            url     : 'api/getRsvpDeadline',
            method  : 'get',
            data    : {
                meetingDate: this.meetingData.meeting_date,
                daysToMeetingDate: rsvpDeadlineDays
            }
        }).then((data) => {
            let rsvp_date = moment(data.data).format('L');
            let rsvp_deadline_datetime = moment(rsvp_date + " " + meeting_time);

            $(".rsvp_date_time").text(rsvp_date + ' ' + this.meetingData.meeting_time + ' '+ this.meetingData.timezone_name);
            $(".meeting_date_time").text(meeting_date + ' ' + this.meetingData.meeting_time + ' ' + this.meetingData.timezone_name);

            setInterval(function(){
                let now = moment().tz(timezone).format();
                let duration = moment.duration(rsvp_deadline_datetime.diff(now));
                $(".rsvp_day_counter").text(duration.asDays().toFixed(0));
                $(".rsvp_hour_counter").text(duration._data.hours);
                $(".rsvp_min_counter").text(duration._data.minutes);
            }, 1000);
        });
    }

    ready() {
        let pathArray = window.location.pathname.split('/');
        let send_communications = false;

        this.meetingId = pathArray[pathArray.length -1];
        this.canManage = false;
        this.meetingPhase = 'pre';

        this.getCommunications()

        this.getCanManage(false).done((data) => {
            this.canManage = data.can_manage;
            this.showAddMe = data.show_add_me;
        })

        this.getMeetingPhase(false).done((data) => {
            this.meetingPhase = data.meeting_phase;
        })

        this.getMeetingData(false).done((data) => {
            this.meetingData = data.data
        });

        let default_project_program_type_config = app.appData.defaultProjectProgramTypeConfig;
        let project_program_type_config = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id];

        send_communications = project_program_type_config.configuration.manage_attendance.send_communications != undefined ? project_program_type_config.configuration.manage_attendance.send_communications : default_project_program_type_config.manage_attendance.send_communications;
        let has_step2 = project_program_type_config.configuration.sb.meeting_request.has_step_2 != undefined ? project_program_type_config.configuration.sb.meeting_request.has_step_2 : false

        let check_shares_office_with_speaker = 'scheduler' in project_program_type_config.configuration
                && 'new_low_registration' in project_program_type_config.configuration.scheduler
            ? project_program_type_config.configuration.scheduler.new_low_registration?.check_shares_office_with_speaker ?? false
            : ('scheduler' in default_project_program_type_config && 'new_low_registration' in default_project_program_type_config.scheduler
                ? default_project_program_type_config.scheduler.new_low_registration?.check_shares_office_with_speaker ?? false
                : false);

        let check_user_type = 'show_shares_office_with_speaker' in app.appData.layout.attendance
                && 'user_type_ids' in app.appData.layout.attendance.show_shares_office_with_speaker
                && app.appData.layout.attendance.show_shares_office_with_speaker?.user_type_ids.includes(app.appData.curUserType.user_type_id);

        let show_foso_link = (check_shares_office_with_speaker && check_user_type) ? true : false;
        let show_abs_link = 'abs' in app.appData.layout.attendance &&
            'hcp_compliance_check' in app.appData.layout.attendance.abs ? app.appData.layout.attendance.abs.hcp_compliance_check : false;

        let notice_msg = app.appData.layout.attendance.notice_msg ?? false;
        let compliance_notice_msg = app.appData.layout.attendance?.compliance_notice_msg ?? false;
        let notice_content = app.appData.layout.attendance?.notice_content ?? "";

        // if we comde from meeting request and they have step do allow management
        this.canManage = (this.canManage || (has_step2 && app.appData.fromMeetingRequest))
        app.appData.fromMeetingRequest = false;

        this.eventTypeConfig = {}
        Object.values(app.appData.eventTypes).forEach(category => {
            Object.values(category).forEach(event_type => {
                if(event_type.id == this.meetingData.event_type_id) {
                    this.eventTypeConfig = event_type.config.attendance || {}
                }
            })
        })

        this.isPatientProg = this.meetingData.event_type_sub_code == 'PTNTPGM'
        let attendeeListTemplate = this.meetingPhase + (this.canManage ? '' :  '_readonly');
        let listColumns = app.appData.layout.attendance.list[attendeeListTemplate +  (this.isPatientProg ? '_patient' : '')] ?? app.appData.layout.attendance.list[attendeeListTemplate]
        app.appData.layout.attendance.listColumns = listColumns
        if(typeof app.appData.serviceTypes[this.meetingData.service_id] == 'undefined' || !parseInt(app.appData.serviceTypes[this.meetingData.service_id].has_meals)) {
            listColumns = listColumns.filter(c => c.columnKey !== 'had_meal');
        }

        if(typeof app.appData.layout.attendance.show_evaluations == 'undefined') {
            app.appData.layout.attendance.show_evaluations = { user_type_ids : [] };
        }

        let show_attendee_evals = app.appData.layout.attendance.show_evaluations.user_type_ids.includes(parseInt(app.appData.curPosition.user_type_id));
        if(!show_attendee_evals && this.meetingPhase == 'postmeeting' ) {
            listColumns = listColumns.filter(c => c.columnKey !== 'attendee_eval');
        }

        let remote = listColumns.filter(obj => {
            return obj.columnKey === 'remote'
        })
        let can_remote = app.appData.layout.attendance.can_remote ?? '0';
        if (remote &&  can_remote != '1') {
            listColumns = listColumns.filter(obj => {
                return obj.columnKey != 'remote'
            })
        }

        let rsvpDeadlineDays = app.appData.layout.attendance.rsvp_timer[this.meetingPhase].deadline_business_days;
        let pp_type_info = false;

        if (
          typeof project_program_type_config.configuration.manage_attendance
            .project_program_type_disclaimer !== "undefined" &&
          project_program_type_config.configuration.manage_attendance
            .project_program_type_disclaimer !== ""
        ) {
          pp_type_info =
            project_program_type_config.configuration.manage_attendance
              .project_program_type_disclaimer;
        }

        let hipaa = app.appData.layout.attendance.has_hipaa[this.meetingPhase] ?? false;

        this.dom = $(twig({ data: template }).render({
            meeting_id: this.meetingId,
            listColumns: listColumns,
            canmanage: this.canManage,
            showAddMe: this.showAddMe,
            appData: app.appData,
            meetingPhase: this.meetingPhase,
            message: app.appData.request_message ? app.appData.request_message : [],
            venueState: this.meetingData.venue_state,
            meetingData: this.meetingData,
            patientProg: this.isPatientProg,
            eventTypeConfig: this.eventTypeConfig,
            is_send_communications_set: send_communications,
            rsvp_deadline_days: rsvpDeadlineDays,
            show_abs_link: show_abs_link,
            show_foso_link: show_foso_link,
            notice_msg: notice_msg,
            compliance_notice_msg : compliance_notice_msg ?? false,
            notice_content: notice_content ?? "",
            project_program_type_disclaimer : pp_type_info,
            has_hipaa: hipaa
        }));

        $('.content-body').html(this.dom);

        this.attendeeList = new AttendeeList('datatable_attendees', listColumns, this.meetingData);

        this.loadCounts()

        if(rsvpDeadlineDays > 0){
            this.loadRSVPTimer(rsvpDeadlineDays);
        }

        this.targetSearch = new TargetSearch(this.meetingData)

        this.apTargetSearch = new ApTargetSearch(this.meetingData)

        //attended button
        this.dom.find('#datatable_attendees').on('click', '.attended-buttons', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData =  row.data()
            let attended = $(event.target).data('value')

           // Don't update unless the value is differnet
           if(attended != rowData.attended)
           {
                // Update ui
                rowData.attended = attended
                row.data(rowData).draw('page')

               $.ajax({
                    url     : 'api/attendance/attended',
                    method  : 'post',
                    data    : {
                        meeting_id: this.meetingId,
                        attendee_id: rowData.attendee_id,
                        attended: attended
                    }
                }).done((data) => {
                    if(data.success)
                    {

                        this.loadCounts();
                    }
                    else
                    {
                        rowData.attended = (attended == 1 ? 0 : 1 )
                        row.data(rowData).draw()
                        this.flashError('Error saving attendance status')
                    }
                })
            }
        })

        this.dom.find('.attendee_checkbox_all').on('change', (event) => {
            this.dom.find('.attendee-selection').prop('checked', $(event.target).is(':checked')).trigger('change')
        })

        this.dom.find('#datatable_attendees').on('change', '.attendee-selection', (event) => {
            let row = $(event.target).closest('tr')

            if($(event.target).is(':checked')) {
                row.addClass('selected')
            } else {
                row.removeClass('selected')
            }

            this.dom.find('.action-button-row button').prop('disabled', !(this.attendeeList.attendeesTable.rows('.selected').count() > 0))

            event.stopPropagation()
        })

        //had meal button
        this.dom.find('#datatable_attendees').on('click', '.had-meal-buttons', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()
            let hadMeal = $(event.target).data('value')

            if(hadMeal != rowData.had_meal)
            {
                // Update ui
                rowData.had_meal = hadMeal
                row.data(rowData).draw('page')

                $.ajax({
                    url     : 'api/attendance/hadmeal',
                    method  : 'post',
                    data    : {
                        meeting_id: this.meetingId,
                        attendee_id: rowData.attendee_id,
                        had_meal: hadMeal
                    }
                }).done((data) => {
                    if(data.success) {
                         this.loadCounts();
                    } else {
                         rowData.had_meal = (hadMeal == 1 ? 0 : 1 )
                         row.data(rowData).draw()
                         this.flashError('Error saving had meal')
                     }
                })
            }
        })

        this.dom.find('#datatable_attendees').on('click', '.attendee_info_link', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data();

            let attendeeDetailModal = new AttendeeDetailModal(rowData);
            let modal = new bootstrap.Modal(attendeeDetailModal.dom);
            modal.show();
        })

        //had remote button
        this.dom.find('#datatable_attendees').on('click', '.remote-buttons', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()
            let remote = $(event.target).data('value')
            let maxPart = false;
            let types = ['Affiliate', '']
            let typeVal = types.includes(rowData['att_type']);

            this.validateMaxParticipation(rowData).done((data) => {
                if(data.success && this.meetingPhase == 'premeeting' && typeVal)
                {
                    maxPart = true;
                }

                if(remote != rowData.remote)
                {
                    if (remote == '1' || (maxPart && remote == '0')) {
                        new ValidateRemote(remote, row, maxPart)
                    } else {
                        $.ajax({
                            url     : 'api/attendance/changeremote',
                            method  : 'post',
                            data    : {
                                meeting_id: $('#meeting_id').val(),
                                attendee_id: rowData.attendee_id,
                                remote: remote
                            }
                        })
                        rowData.remote = remote;
                        row.data(rowData).draw('page');
                    }
                }
            });
        })

         //rsvp button
         this.dom.find('#datatable_attendees').on('click', '.rsvp-buttons', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data();
            let rsvped = $(event.target).data('value')
            let data = {
                attendee_id: rowData.attendee_id,
            }

            let maxp_check = () => {
                $.ajax({
                    url     : 'api/attendance/maxpAttendanceAllowed',
                    method  : 'get',
                    data    : {
                        meeting_id: rowData.meeting_id,
                        customer_id: rowData.customer_id
                    }
                }).done((allowed) => {
                    callback(allowed);
                });
            }

            let callback = (allowed) => {
                if(!allowed){
                    new ValidateRsvp();
                }else{
                    if(rsvped) {
                        rowData.confirmed = data.confirmed = "1",
                        rowData.registered = data.registered = "1",
                        rowData.canc_declined = data.canc_declined = "0"
                    } else {
                        rowData.canc_declined = data.canc_declined =  "1"
                    }

                    // Update ui
                    row.data(rowData).draw('page')

                    $.ajax({
                        url     : 'api/attendees/' + rowData.attendee_id ,
                        method  : 'post',
                        data    : data
                    }).done((data) => {
                        if(data.success) {
                            this.loadCounts();
                        } else {
                            row.data().draw('page')
                            this.flashError('Error rsvping')
                        }
                    })
                }
            }

            if(rsvped && rowData.remote != 1){
                maxp_check();
            }else{
                callback(true);
            }
        })

        this.dom.find('#datatable_attendees').on('click', '.remove-attendee', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()

            //remove button so user doesn't click a million times
            $(event.target).remove()
            row.remove().draw('page')

            this.removeAttendee(rowData['attendee_id']).done((data) => {
                if(data.success)
                {
                    this.loadCounts()
                }
                else
                {
                    this.flashError('Error removing participant')
                    this.attendeeList.attendeesTable.row.add(rowData).draw()
                }
            })
        })

        this.dom.find('#datatable_attendees').on('click', '.communication-history', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()
            let config = {
                "meetingId": this.meetingId,
                "meetingPhase": this.meetingPhase,
                "communications": this.communications
            }

            new CommunicationHistoryModal(config, rowData)
        })

        this.dom.find('#datatable_attendees').on('click', '.edit-email', (event) => {
            let elem = $(event.target)

            elem.closest('.edit-email-read').hide()

            let tagInput = elem.parents('td').find('.edit-email-edit textarea')
            new Tagify (tagInput[0], {
                delimiters: ';',
                originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(';'),
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                duplicates: false,
                callbacks: {

                    "change": (e) => { tagInput.val(e.detail.value) }
                }
            });

            elem.parents('td').find('.edit-email-edit').show()
            event.preventDefault();
        })

        this.dom.find('#datatable_attendees').on('click', '.save-email', (event) => {
            let elem = $(event.target)
            let row = this.attendeeList.attendeesTable.row(elem.parents('tr'))
            let rowData = row.data()
            let email = elem.parents('td').find('.edit-email-edit textarea').val()

            if (app.appData.layout.attendance.required_email_for_remote_attendee && rowData.remote == '1' && !email) {
                elem.parents('td').find('.edit-email-edit').append('<div class="invalid-feedback">E-mail required for remote attendee.</div>')
                    .find('.invalid-feedback').show()
                return
            }

            elem.parents('td').find('.edit-email-edit').find('.invalid-feedback').remove()

            let attendeeData = {
                attendee_id: rowData.attendee_id,
                email: email
            }

            this.updateAttendee(attendeeData).done((response) => {
                if(response.success) {
                    if(response.removed_emails != "") {
                        this.dom.trigger('failedAddByEmail', [event, response]);
                    }
                    rowData.email = response.data['email'];
                    row.data(rowData).draw()
                }
            })

            event.preventDefault();
        })

        this.dom.find('#datatable_attendees').on('click', '.close-email-edit', (event) => {
            let elem = $(event.target)

            elem.closest('.edit-email-edit').hide()
            elem.parents('td').find('.edit-email-read').show()

            event.preventDefault();
        })

        //Article Reprint
        this.dom.find('#datatable_attendees').on('click', '.received_article_reprint-buttons', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()
            let received_article_reprint = $(event.target).data('value')

            if (received_article_reprint !== 0) {
                let articleReprintModal = new CustomModal({
                    title: 'Enter Article Reprint Quantity',
                    content: `<div class="form-group article_reprint_count-container">
                    <label for="article_reprint_count">How many did the participant receive?</label>
                    <input type="number" class="form-control form-control-sm" id="count" name="count" value="" required/>
                    <label class="text-danger" id="error_message"></label>
                    </div>`,
                    buttons: [
                        {
                            text: "Submit",
                            id: 'reprintqtysubmitBtn',
                            class: 'btn-primary',
                            action: () => {
                                let count = $('#count').val();
                                if (count !== "") {
                                    this.updateArticleReprint(this.meetingId, rowData.attendee_id, received_article_reprint, count).then((data) => {
                                        if (data.success) {
                                            this.attendeeList.attendeesTable.ajax.reload();
                                            articleReprintModal.close();
                                        }
                                    });
                                } else {
                                    articleReprintModal.open();
                                    $('#error_message').html('Please fill this field!');
                                }
                            },
                        },
                        {
                            text: "Cancel",
                            close: true,
                            class: 'btn-secondary'
                        }
                    ]
                })
                articleReprintModal.open();
            } else {
                let count = 0;
                this.updateArticleReprint(this.meetingId, rowData.attendee_id, received_article_reprint, count).then((data) => {
                    if (data.success) {
                        this.attendeeList.attendeesTable.ajax.reload();
                    }
                });
            }
        })

        //foso attestation
        this.dom.find('#datatable_attendees').on('click', '.foso-attestation-no:not(.active)', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()
            let elem = $(event.target)

            let fosoAttestationText = "I attest that the HCP does not work with the Speaker and a Change Request has been submitted in Veeva for the HCP. " +
                "I acknowledge that I assume the risk that it may be determined that the HCP works with the Speaker.";

            this.fosoAttestationModal = new CustomModal({
                title: 'Attestation',
                content: "<p>" + fosoAttestationText + "</p>",
                buttons: [
                    {
                        text: "OK",
                        id: 'attestationConfirmBtn',
                        class: 'btn-primary',
                        close:true,
                        action: () => {
                            $(elem).closest('.foso-buttons').children('.foso-attestation-yes').removeClass('active');
                            $(elem).addClass('active');
                            $.ajax({
                                url: 'api/attendance/attestfoso',
                                method: 'post',
                                data: {
                                    meeting_id: this.meetingId,
                                    attendee_id: rowData.attendee_id
                                }
                            });
                        }
                    },
                    {
                        text: "Cancel",
                        close: true,
                        class: 'btn-secondary'
                    }
                ]
            }).open();
        })

        //attendee signature
        this.dom.find('#datatable_attendees').on('click', '.view-signature', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()

            new CustomModal({
                title: 'Signature of ' + rowData.firstname + ' ' + rowData.lastname,
                content:'<div>' + rowData.signature + '</div>',
                buttons: [
                    {
                        text: "Close",
                        close: true,
                        class: 'btn-primary'
                    }
                ]
            }).open();
        })

        //reminder button
        this.dom.find('#datatable_attendees').on('click', '.reminder-email', (event) => {
            event.preventDefault();
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()

            $.ajax({
                url     : 'api/attendance/sendreminder',
                method  : 'post',
                data    : {
                    meeting_id: this.meetingId,
                    attendee_id: rowData.attendee_id,
                }
            }).done((data) => {
                if(data.success && (data.data.length > 0)) {
                    rowData.reminder_email_timestamp = moment().format('X')
                    rowData.num_reminder_sent = parseInt(rowData.num_reminder_sent) + 1
                    row.data(rowData).draw()
                }
            });
        })


        //add associated prescriber button
        this.dom.find('#datatable_attendees').on('click', '.add_ap_button', (event) => {
            event.preventDefault()
            let row = this.attendeeList.attendeesTable.row($(event.target).parents('tr'))
            let rowData = row.data()

            this.apTargetSearch.reset()
            this.apTargetSearch.dom.find('#job_title').val('PRESCRIBERS');
            this.apTargetSearch.dom.find('#attendee_id').val(rowData.attendee_id)
            this.apTargetSearch.dom.find('#nonprescriber_target_id').val(rowData.target_id)
            this.apTargetSearch.modal.show();
        })

        //add attendees button
        this.dom.find('#add_attendees_button').on('click', (event) => {
            event.preventDefault()
            this.targetSearch.reset()
            this.targetSearch.modal.show()
        })

        //add employee button
        this.dom.find('#add_employee_button').on('click', (event) => {
            event.preventDefault();
            this.employeeSearch = new EmployeeSearch();
        })

        this.dom.find('#add_hipaa_button').on('click', (event) => {
            event.preventDefault();
            new AddHipaa(this.meetingPhase);
        })

        this.dom.find('#add_non_reportable_button').on('click', (event) => {
            event.preventDefault();
            new AddNonReportable(this.meetingPhase);

        })

        this.dom.find('#manage_groups_button').on('click', (event) => {
            event.preventDefault();
            this.dom.trigger('ShowManageGroups', [event])
        })

        this.dom.find('.download-certs').on('click', () => {
            let attendees = this.attendeeList.attendeesTable.rows('.selected').data().toArray().map(x => x['attendee_id'])
            let params = {
                attendees: attendees,
                media: this.meetingData.attendance_certificates.map(x => x['media_id']),
                meeting_id: this.meetingId
            }

            window.location = 'api/attendance/zipcerts?' + $.param(params)

        })

        this.dom.find('.send-emails').on('click', () => {
            let config = {
                "meetingId": this.meetingId,
                "meetingPhase": this.meetingPhase,
                "communications": this.communications
            }
            let nonotypes = ['Speaker', 'Rep', 'HIPAA'];

            let recipients = this.attendeeList.attendeesTable.rows('.selected').data().toArray()

            recipients = recipients.filter((elem) => {
                return elem.email != '' && !nonotypes.includes(elem.att_type)
            })

            new SendCommunication(config, recipients, false);
        })

        this.dom.find('.email-metrics').on('click', (event) => {
            event.preventDefault()
            let config = {
                "meetingId": this.meetingId,
                "meetingPhase": this.meetingPhase,
                "communications": this.communications
            }

            new EmailMetricsModal(config);
        })

        this.dom.find('#complete-attendance').on('click', () => {
            $.ajax({
                url     : 'api/attendance/complete',
                method  : 'POST',
                data    : {
                    meeting_id: this.meetingId
                }
            }).then(() => {
                if(app.appData.layout.attendance.send_communications.thank_mail) {
                    $.ajax({
                        url     : `api/attendance/${this.meetingId}/communications/thankmail`,
                        method  : 'POST',
                        data    : {
                            meeting_id: this.meetingId,
                            media_type_name: app.appData.layout.attendance.send_communications.thank_mail
                        }
                    }).then((response) => {
                        if(response.recipients.length == 0) {
                            this.dom.find('#meeting_details_button').trigger('click')
                        } else {
                            let config = {
                                "meetingId": this.meetingId,
                                "meetingPhase": this.meetingPhase,
                                "communications": this.communications
                            }
                            let thanksDialog = new SendCommunication(config, response.recipients, true)
                            thanksDialog.dom.on('hidden.bs.modal', () => {
                                this.dom.find('#meeting_details_button').trigger('click')
                            })
                        }
                    })
                } else {
                    this.dom.find('#meeting_details_button').trigger('click')
                }
            })
        })

        this.dom.find('.attendee-val-message').on('click', () => {
            $('.attendee-val-message').hide();
        })

        //trigger things
        this.dom.on('targetInviteClicked', (event, origEvent, data) => {
            let is_pre_meeting = this.meetingPhase === 'premeeting';
            let is_att_remote = data.remote != undefined ? data.remote : 0;
            this.registrationLimit(data).then(reg_info => {
                if (is_pre_meeting && reg_info.success && !is_att_remote) {
                    this.registrationLimitModal = new RegistrationLimitModal(data, reg_info);
                } else {
                    this.attendeeQuestions = new AttendeeQuestions('target', this.meetingPhase, {
                        attendeeData: data,
                        meetingData: this.meetingData
                    })

                    if (this.attendeeQuestions.showDialog()) {
                        this.targetSearch.modal.hide();

                        let attendeeQuestionsDom = this.attendeeQuestions.render().dom;

                        attendeeQuestionsDom.find('#questions_ship_to').select2({
                            ajax: {
                                url: "api/attendance/searchshipto",
                                dataType: "json",
                                data: (params) => {
                                    return {
                                        query: params.term
                                    };
                                },
                                method: "GET",
                                processResults: (data) => {
                                    Object.keys(data).forEach((key) => {
                                        data[key]['id'] = data[key]['ship_to'].toUpperCase();
                                        data[key]['text'] = `${data[key]['ship_to']}, ${data[key]['customer_name']}, ${data[key]['line_1']}, ${data[key]['city']}, ${data[key]['state']}, ${data[key]['zip']}`;
                                    });

                                    return {
                                        results: data
                                    }
                                },
                                delay: 300
                            },
                            templateResult: (data) => {
                                if(data.id && data.text) {
                                    return  data.text;
                                }
                            },
                            templateSelection: (data) => {
                                if(data.id && data.text) {
                                    return  data.text;
                                }
                            },
                            minimumInputLength: 2,
                            placeholder: "Please enter a ship to # or customer name to search",
                            dropdownParent: attendeeQuestionsDom.find('.modal-body')
                        });

                        this.attendeeQuestions.modal.show();
                    } else {
                        data.hadMeal = 2
                        this.addTargetAttendee(data).done(() => {
                            this.targetSearch.modal.show()
                            $(origEvent.target).parents('td').html('Added')
                        })
                    }
                }
            })
        });

        this.dom.find('#add_myself').on('click', () => {
            let data = {
                wwid: app.appData.curPosition.wwid
            };

            this.addEmployeeAttendee(data).done(() => {
                app.navigate('attendance', [this.meetingId])
            })
        })

        this.dom.on('employeeAddClicked', (event, origEvent, data) => {
            this.attendeeQuestions = new AttendeeQuestions('employee', this.meetingPhase, {attendeeData:data, meetingData:this.meetingData})

            if(this.attendeeQuestions.showDialog())
            {
                this.employeeSearch.modal.hide()
                this.attendeeQuestions.render().modal.show();
            }
            else
            {
                this.addEmployeeAttendee(data).done(() => {
                    $(origEvent.target).parents('td').html('Added')
                })
            }
        })

        this.dom.on('participantAdded', () => {
            this.flashSuccess('Participant Added')
            this.attendeeList.attendeesTable.ajax.reload()
            this.loadCounts()
        })

        this.dom.on('failedAddByEmail', () => {
            this.flashError("Employee should be added via clicking 'Add Employees' button.")
        })

        this.dom.on('targetQuestionsAnswered', (event, origEvent, data) => {
            data.attFlags = data.answers
            data.hadMeal = data.answers.had_meal

            this.addTargetAttendee(data).done((response) => {
                if(response.success == false
                    && response.removed_emails !== undefined && response.removed_emails != '') {
                        this.dom.trigger('failedAddByEmail', [event, response]);
                }
                this.targetSearch.reloadDatatable()
                this.targetSearch.modal.show()
            })
        })

        this.dom.on('employeeQuestionsAnswered', (event, origEvent, data) => {
            data.attFlags = data.answers
            data.hadMeal = data.answers.had_meal
            this.addEmployeeAttendee(data).done(() => {
                this.employeeSearch.reloadDatatable()
                this.employeeSearch.modal.show()
            })
        })

        this.dom.on('addViaFormClicked', (event, origEvent, data) => {
            data['country'] = 'US'
            new AddForm(this.meetingPhase, data, this.meetingData)
        })

        this.dom.on('emailsSent', (event, origEvent, data) => {
            let message = '';
            if(data.sentCount > 0) {
                message = data.sentCount + " emails sent. "
            }

            if(data.failCount > 0) {
                message += data.failCount + " failed to send."
            }

            this.flashSuccess(message)
        })

        $(document).on('ShowEditGroup', (event, origEvent, data) => {
            data.meetingData = this.meetingData
            this.manageGroups.modal.hide();
            new EditGroup(data);
        })

        $(document).on('ShowManageGroups', () => {
            let config = {
                "meetingData": this.meetingData,
                "meetingId": this.meetingId,
                "meetingPhase": this.meetingPhase
            }

            this.manageGroups = new ManageGroup(config);
        })

        this.dom.find('div#meeting_details_button, span#meeting_details_button').off().on('click', () => {
            app.navigate('meeting', [this.meetingId])
        });

        $('.specialty-legend').first().on('click', (e) => {
            e.preventDefault()
            new SpecialtyLegend(this.meetingData);
        })
   }
}
