import {Card} from "./Card";

let speaker_card_template = require('./speaker_side_card.twig');
let speaker_card_speaker_template = require('./speaker_side_card_speaker.twig');

export class SpeakerSideCard extends Card
{
    constructor(topic_name = '') {
        super();
        let card_count = parseInt($('.speaker_side_card').length);
        let topic_speakers = 'Topic ' + app.appData.labels.speaker;
        let topic_count_speakers = 'Topic ' + (card_count + 1) + ' ' + app.appData.labels.speaker;
        let card_title = card_count == 0 ? topic_speakers : topic_count_speakers;

        this.dom = $(twig({data: speaker_card_template}).render({
            card_title: card_title,
            card_tooltip: topic_name,
            card_count: card_count
        }));

        this.dom.show();
    }

    empty() {
        this.dom.find('.card-body').empty();
    }

    expandCollapseDetails($row, collapse) {
        $($row).each(function(){
            if(collapse){
                $(this).find('.speaker_side_less_detail').stop().slideUp(100);
            }else{
                $(this).find('.speaker_side_less_detail').stop().slideDown(100);
            }
        });
    }

    updateSpeakers(speaker_ids, topic_id, project_id, brand_id) {
        if(!Array.isArray(speaker_ids) || !speaker_ids.length){
            return false;
        }

        this.dom.find('.card-body').empty();

        $.ajax({
            url: 'api/meetingrequest/speakersdetails',
            data: {
                brand_id: brand_id,
                project_id: project_id,
                speaker_ids: speaker_ids,
                topic_id: topic_id,
                meeting_date: $('#meeting_date').val(),
                program_type_id: $('#program_type_dd').val(),
                include_program_group_type_id: app.appData.layout.meeting_request.speaker_brand_program_group_threshold_check,
                hub_meeting_id: $('#hub_meeting_id').val(),
                tour_id: $('#tour_id').val()
            }
        }).then(data => {
            let body = this.dom.find('.card-body');

            body.empty();

            let counter = 0;
            Object.values(data).forEach(speaker => {
                counter++;
                let speaker_available_check = typeof app.appData.layout.meeting_request.ignore_speaker_meeting_date_check == 'undefined'
                    ? false
                    : !app.appData.layout.meeting_request.ignore_speaker_meeting_date_check;

                let applyingHub = app.pages.meetingrequest.applyingHub;
                if(speaker_available_check && !speaker.speaker_available && !applyingHub) {
                    // When multiple speaker drop-downs exist, this may match multiple select elements
                    let $spkr_selects = $('.speaker_dd option[value='+speaker.speaker_id+']').parents('select');
                    let $ptnt_selects = $('.patient_speaker_dd option[value='+speaker.speaker_id+']').parents('select');

                    // Unselect speaker wherever they are currently chosen
                    $.merge($spkr_selects, $ptnt_selects).each(function(){
                        if($(this).val() == speaker.speaker_id){
                            $(this).val('').change();
                        }
                    });

                    displayNotification({
                        message: 'That speaker ('+ speaker.firstname + ' ' + speaker.lastname +') is not available on the selected date, clearing out speaker selection.',
                        type: false
                    })
                } else {
                    let speaker_pic_url = $('base').attr('href')+"api/speakers/"+speaker.speaker_id+"/pic";
                    speaker.slot = app.appData.labels.speaker + ' ' + counter;
                    speaker.speaker_pic_url = speaker_pic_url;
                    speaker.show_local_utilization = app.appData.layout.meeting_request.show_local_utilization;
                    speaker.show_available = app.appData.layout.meeting_request.show_available;
                    speaker.sfmember_bio = app.appData.layout.speaker_detail?.sfmember_bio ?? false;
                    speaker.sfmember_label = app.appData.layout.speaker_detail?.sfmember_label ?? '';

                    speaker.contracted.count_meetings       = parseFloat(speaker.contracted.count_meetings);
                    speaker.contracted.expenses             = parseFloat(speaker.contracted.expenses);
                    speaker.contracted.threshold_amount     = parseFloat(speaker.contracted.threshold_amount);
                    speaker.contracted.threshold_meetings   = parseFloat(speaker.contracted.threshold_meetings);

                    speaker.no_travel_days = speaker.travel_days.filter(day => day.willing).length == 0;

                    // When not enabled, only show primary speaker
                    let enable_alts = app.appData.opco_config.sb.meeting_request.show_alt_speaker_sidecard ?? false;
                    if(enable_alts || counter == 1){
                        let speaker_detail_dom = $(twig({data: speaker_card_speaker_template}).render(speaker));
                        body.append(speaker_detail_dom);
                    }
                }
            });

            // Only show "Speaker 1" etc labels when multiple speakers
            $(".speaker_side_card").each(function(){
                let slot_labels = true;
                if($(this).find('.card-body').children().length == 1){
                    slot_labels = false;
                }

                slot_labels ? $(this).find('.speaker_side_card_slot').show() : $(this).find('.speaker_side_card_slot').hide();
            });

            if(!app.isMobile()){
                // Default each speaker row to show minimum information except the primary until hovered over
                $(".speaker_side_card").show().each(function(){
                    $(this).find('.row').slice(1).each(function(){
                        app.pages.meetingrequest.speaker_side_cards[0].expandCollapseDetails($(this), true);
                    });
                }).on('mouseenter', function() {
                    $(this).find('.row').slice(1).each(function(){
                        app.pages.meetingrequest.speaker_side_cards[0].expandCollapseDetails($(this), false);
                    });
                }).on('mouseleave', function() {
                    $(this).find('.row').slice(1).each(function(){
                        app.pages.meetingrequest.speaker_side_cards[0].expandCollapseDetails($(this), true);
                    });
                });
            }
        })
    }
}
